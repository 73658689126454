import React from 'react';
import { AreaChart, Area, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import colors from 'styles/colors';
import { Card } from 'components/Form/Card';
import Row from 'components/Form/Row';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMobilePhone} from "@fortawesome/free-solid-svg-icons";

const cardStyles = `
span.val {
  &.up { color: ${colors.success}; }
  &.down { color: ${colors.danger}; }
}
.rank-average {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
}
.chart-container {
  margin-top: 1rem;
}
`;

const makeRankStats = (summaryData: any) => {
  const desktopRank = parseFloat(summaryData.desktop);
  const mobileRank = parseFloat(summaryData.mobile);
  const percentageChange = ((desktopRank - mobileRank) / mobileRank) * 100;
  return {
    desktopRank,
    mobileRank,
    percentageChange,
  };
};

const makeChartData = (data: any) => {
  return [
    { name: 'Desktop', uv: parseFloat(data.desktop) },
    { name: 'Mobile', uv: parseFloat(data.mobile) },
    { name: 'Other', uv: parseFloat(data.other) },
  ];
};

function Chart(chartData: { name: string; uv: number }[], data: any) {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart width={400} height={100} data={chartData}>
        <CartesianGrid strokeDasharray="4" strokeWidth={0.25} verticalPoints={[50, 100, 150, 200, 250, 300, 350]} horizontalPoints={[25, 50, 75]} />
        <Tooltip contentStyle={{ background: colors.background, color: colors.textColor, borderRadius: 4 }}
          labelFormatter={(value) => ['Rank Type: ', value]} />
        <Area type="monotone" dataKey="uv" stroke="#9fef00" fillOpacity={1} name="Rank" fill={`${colors.backgroundDarker}a1`} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

const DeviceSummaryCard = (props: { data: any, title: string, actionButtons: any,icon: any }): JSX.Element => {
  const summaryData = props.data.result.summary_0 || {};
  const { desktopRank, mobileRank, percentageChange } = makeRankStats(summaryData);
  const chartData = makeChartData(summaryData);

  return (
    <>
      {/* Desktop Rank Card */}
      <Card heading={`${props.title} - Desktop`} actionButtons={props.actionButtons} styles={cardStyles} icon={props.icon}>
        <div className="rank-average">{desktopRank.toFixed(2)}</div>
        <Row lbl="Change since Yesterday" val={`${percentageChange > 0 ? '+' : ''} ${percentageChange.toFixed(2)}%`} />
        <div className="chart-container">
          {Chart(chartData, summaryData)}
        </div>
      </Card>

      {/* Mobile Rank Card */}
      <Card heading={`${props.title} - Mobile`} actionButtons={props.actionButtons} styles={cardStyles} icon={faMobilePhone}>
        <div className="rank-average">{mobileRank.toFixed(2)}</div>
        {/* Assuming you want to display the mobile rank in a separate card */}
        <Row lbl="Change since Yesterday" val={`${percentageChange > 0 ? '+' : ''} ${percentageChange.toFixed(2)}%`} />
        <div className="chart-container">
          {Chart(chartData, summaryData)}
        </div>
      </Card>
    </>
  );
}

export default DeviceSummaryCard;
