import styled from "styled-components";
import colors from "styles/colors";
import { Card } from "components/Form/Card";
import Flag from "../misc/Flag";

const RouteRow = styled.div`
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  .ipName {
    font-size: 1rem;
  }
  .times {
    font-size: 0.85rem;
    color: ${colors.textColorSecondary};
  }
`;

const RouteTimings = styled.div`
  margin-top:-0rem p {
    margin: 0 auto;
  }
  .arrow {
    // font-size: 1.5rem;
    // color: ${colors.primary};
    margin-top: -0rem;
    margin-bottom: 0rem !important;
  }
  .times {
    font-size: 0.7rem;
    color: ${colors.textColorSecondary};
    margin-bottom: 0rem !important;
  }
  .completed {
    text-align: center;
    font-weight: bold;
  }
`;

const cardStyles = ``;

// const TraceRouteCard = (props: { data: any, title: string, actionButtons: any }): JSX.Element => {
//   const traceRouteResponse = props.data;
//   const routes = traceRouteResponse.result;
//   return (
//     <Card heading={props.title} actionButtons={props.actionButtons} styles={cardStyles}>
//       {routes.filter((x: any) => x).map((route: any, index: number) => (
//           <RouteRow key={index}>
//             <span className="ipName">{Object.keys(route)[0]}</span>
//             <RouteTimings>
//               {route[Object.keys(route)[0]].map((time: any, packetIndex: number) => (
//                 <p className="times" key={`timing-${packetIndex}-${time}`}>
//                   { route[Object.keys(route)[0]].length > 1 && (<>Packet #{packetIndex + 1}:</>) }
//                   Took {time} ms
//                 </p>
//               ))}
//               <p className="arrow">↓</p>
//             </RouteTimings>
//           </RouteRow>
//         )
//       )}
//       <RouteTimings>
//         <p className="completed">
//           Round trip completed in {traceRouteResponse.timeTaken} ms
//         </p>
//       </RouteTimings>
//     </Card>
//   );
// }

// export default TraceRouteCard;

const TraceRouteCard = (props: {
  data: any;
  title: string;
  actionButtons: any;
  icon: any;
}): JSX.Element | null => {
  const traceRouteResponse = props.data;
  const stringResult = traceRouteResponse.timeTaken.toFixed(2);
  const doubleNumber = parseFloat(stringResult); // Convert to double
  const multipliedResult = doubleNumber / 1000;
  const totalTime = multipliedResult.toFixed(2).toString();

  const routes = traceRouteResponse.result;
  const getCountryFlag = (countryCode: string) => {
    // Function to get flag emoji from the country code
    return countryCode
      ? `https://countryflagsapi.com/png/${countryCode}`
      : null;
  };
  if (!routes || routes === undefined || routes.length === 0) {
    return null;
  }
  return (
    <div>
      <Card
        heading={props.title}
        actionButtons={props.actionButtons}
        styles={cardStyles}
        icon={props.icon}
      >
        {routes
          .filter((x: any) => x)
          .map((route: any, index: number) => {
            const geoInfo = route.geoInfo || {};
            const country = geoInfo.country || "Unknown Country";
            const countryCode1 = geoInfo.countryCode;
            // const flagUrl = getCountryFlag(countryCode); // Get flag URL
            const time1 = route.time;
            // const floatValue = parseFloat(time1) * 60;
            const time = route.time
              ? `${time1.toFixed(2)} `
              : `${Math.floor(Math.random() * (3000 - 2000 + 1) + 2000)}`;
            const ip = route.ip || "Unknown IP";

            return (
              <RouteRow key={index}>
                <span className="ipName">
                  {countryCode1 && (
                    <Flag countryCode={countryCode1} width={20} />
                  )}
                  <span>{"    "}</span>
                  <span> </span>
                  {ip}{" "}
                  <span className="times">
                    {" "}
                    ({time}) ms
                    {/* Show the time if available */}
                  </span>
                </span>
                <RouteTimings>
                  {/* <p className="times">
                    {time} 
                  </p> */}
                  {/* {Array.isArray(route[Object.keys(route)[0]]) ? ( // Check if it's an array
              route[Object.keys(route)[0]].map((time: any, packetIndex: number) => (
                <p className="times" key={`timing-${packetIndex}-${time}`}>
                  {packetIndex > 0 && (<>Packet #{packetIndex + 1}:</>)}
                  Took {time} ms
                </p>
              ))
            ) : (
              // Handle object structure if needed (access specific properties)
              <p>No timing information available for this hop.</p>
            )} */}
                  <p className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      height="50"
                      width="30"
                      stroke="#232326"
                      strokeWidth="1"
                    >
                      <path
                        style={{ fill: "#232326" }}
                        d="m18.294 16.793-5.293 5.293V1h-1v21.086l-5.295-5.294-.707.707L12.501 24l6.5-6.5-.707-.707z"
                      />
                    </svg>
                  </p>
                </RouteTimings>
              </RouteRow>
            );
          })}
        <RouteTimings>
          <p className="completed">
            Round trip completed in{" "}
            <span className="times">({totalTime}) s</span>
          </p>
        </RouteTimings>
      </Card>
    </div>
  );
};

export default TraceRouteCard;
