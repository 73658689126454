import React, { useState, useEffect } from "react";
import { Card } from "components/Form/Card";
import Row from "components/Form/Row";
import CountryFlag from "react-country-flag";

const ApiResultCard = (props: {
  data: any;
  title: any;
  actionButtons: any;
  icon: any;
}): JSX.Element => {
  const { data, actionButtons } = props;
  const [ipDetails, setIpDetails] = useState<
    { ip: string; countryCode: string; countryName: string; flagged: boolean }[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIpDetails = async () => {
      const maxIpsToScan = 35;
      const ipDetailsPromises = data.ips
        .slice(0, maxIpsToScan)
        .map((ip: string, index: number) =>
          new Promise((resolve) => setTimeout(resolve, index * 100)) // Delay each request by 100ms
            .then(() =>
              fetch(`http://ip-api.com/json/${ip}`)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  return response.json();
                })
                .then((ipDetail) => ({
                  ip,
                  countryCode: ipDetail.countryCode || "",
                  countryName: ipDetail.country || "",
                  flagged: ipDetail.countryCode !== "", // Flag if countryCode is not empty
                }))
                .catch((err) => {
                  // console.log(`Failed to fetch details for IP: ${ip}`, err);
                  return {
                    ip,
                    countryCode: "",
                    countryName: "",
                    flagged: false,
                  };
                })
            )
        );

      try {
        const resolvedIpDetails = await Promise.all(ipDetailsPromises);
        const fullIpDetails = [
          ...resolvedIpDetails,
          ...data.ips.slice(maxIpsToScan).map((ip: string) => ({
            ip,
            countryCode: "",
            countryName: "",
            flagged: false,
          })),
        ];
        setIpDetails(fullIpDetails);
      } catch (err) {
        setError("Failed to fetch IP details.");
      }
    };

    if (data.ips && data.ips.length > 0) {
      fetchIpDetails();
    }
  }, [data.ips]);

  const getUniqueValues = <T extends string | number>(
    arr: T[] | null | undefined
  ): T[] => {
    if (!arr) {
      return [];
    }
    return Array.from(new Set(arr));
  };

  const sortByCountry = (
    a: { countryCode: string; flagged: boolean },
    b: { countryCode: string; flagged: boolean }
  ) => {
    // Display flagged IPs on top
    if (a.flagged && !b.flagged) {
      return -1;
    }
    if (!a.flagged && b.flagged) {
      return 1;
    }

    // Sort by country code if both are flagged or both are not flagged
    return a.countryCode.localeCompare(b.countryCode);
  };

  return (
    <>
      {/* ASNs Card */}
      {data.asns && data.asns.length > 0 && (
        <Card
          heading={"Autonomous System Number (ASNs)"}
          actionButtons={actionButtons}
          icon={props.icon}
        >
          <div className="content">
            <div>
              {getUniqueValues<string>(data.asns).map(
                (asn: string, index: number) => (
                  <Row key={index} lbl="" val="">
                    <span>{asn}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}

      {/* Interesting URLs Card */}
      {data.interesting_urls && data.interesting_urls.length > 0 && (
        <Card heading={"Interesting URLs"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {getUniqueValues<string>(data.interesting_urls).map(
                (url: string, index: number) => (
                  <Row key={url} lbl="" val="">
                    <span>{url}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}

      {/* IPs Card with Country Flags */}
      {data.ips && data.ips.length > 0 && (
        <Card heading={"Unique IPs"} actionButtons={actionButtons}>
          <div className="content">
            {error && <div className="error">{error}</div>}
            <div>
              {ipDetails
                .sort(sortByCountry)
                .map(({ ip, countryCode, countryName }, index) => (
                  <Row key={index} lbl="" val={""}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <CountryFlag
                        countryCode={countryCode}
                        svg
                        style={{ marginRight: "10px" }}
                      />
                      <span></span>
                      <span style={{ width: "100px", textAlign: "left" }}>
                        {countryName.length > 12 ? countryCode : countryName}
                      </span>
                      <span style={{ flex: "1", textAlign: "right" }}>
                        {ip}
                      </span>
                    </span>
                  </Row>
                ))}
            </div>
          </div>
        </Card>
      )}

      {/* Emails Card */}
      {data.emails && data.emails.length > 0 && (
        <Card heading={"Emails"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {getUniqueValues<string>(data.emails).map(
                (email: string, index: number) => (
                  <Row key={email} lbl="" val="">
                    <span>{email}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}

      {/* Searched Hosts Card */}
      {data.hosts && data.hosts.length > 0 && (
        <Card heading={"Searched Hosts"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {data.hosts
                .map((hostEntry: { host: any; ips: any }) => {
                  const { host, ips } = hostEntry;
                  return { host, ips };
                })
                .sort(
                  (
                    a: { ips: string[]; host: string },
                    b: { ips: string[]; host: string }
                  ) => {
                    if (a.ips.length > 0 && b.ips.length === 0) {
                      return -1;
                    } else if (a.ips.length === 0 && b.ips.length > 0) {
                      return 1;
                    } else {
                      return a.host.localeCompare(b.host);
                    }
                  }
                )
                .map(
                  ({ host, ips }: any, index: string | number | undefined) => (
                    <Row key={index} lbl={host} val={ips.join(", ")} />
                  )
                )}
            </div>
          </div>
        </Card>
      )}

      {/* Twitter People Card */}
      {data.twitter_people && data.twitter_people.length > 0 && (
        <Card heading={"Twitter People"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {getUniqueValues<string>(data.twitter_people).map(
                (twitter_people: string, index: number) => (
                  <Row key={twitter_people} lbl="" val="">
                    <span>{twitter_people}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}

      {/* LinkedIn People Card */}
      {data.linkedin_people && data.linkedin_people.length > 0 && (
        <Card heading={"LinkedIn People"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {getUniqueValues<string>(data.linkedin_people).map(
                (linkedin_people: string, index: number) => (
                  <Row key={linkedin_people} lbl="" val="">
                    <span>{linkedin_people}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}

      {/* LinkedIn Links Card */}
      {data.linkedin_links && data.linkedin_links.length > 0 && (
        <Card heading={"LinkedIn Links"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {getUniqueValues<string>(data.linkedin_links).map(
                (linkedin_link: string, index: number) => (
                  <Row key={linkedin_link} lbl="" val="">
                    <span>{linkedin_link}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}

      {/* Trello URLs Card */}
      {data.trello_urls && data.trello_urls.length > 0 && (
        <Card heading={"Trello URLs"} actionButtons={actionButtons}>
          <div className="content">
            <div>
              {getUniqueValues<string>(data.trello_urls).map(
                (trello_url: string, index: number) => (
                  <Row key={trello_url} lbl="" val="">
                    <span>{trello_url}</span>
                  </Row>
                )
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default ApiResultCard;

// import React, { useState, useEffect } from "react";
// import { Card } from "components/Form/Card";
// import Row from "components/Form/Row";
// import CountryFlag from "react-country-flag";

// const ApiResultCard = (props: {
//   data: any;
//   title: any;
//   actionButtons: any;
// }): JSX.Element => {
//   const { data, title, actionButtons } = props;
//   const [ipDetails, setIpDetails] = useState<{ [key: string]: string }[]>([]);

//   useEffect(() => {
//     console.log(data);
//     const fetchIpDetails = async () => {
//       const ipDetailsPromises = data.ips.map((ip: string) =>
//         fetch(`http://ip-api.com/json/${ip}`).then((response) =>
//           response.json()
//         )
//       );
//       const resolvedIpDetails = await Promise.all(ipDetailsPromises);
//       setIpDetails(resolvedIpDetails);
//     };

//     if (data.ips && data.ips.length > 0) {
//       fetchIpDetails();
//     }
//   }, [data.ips]);

//   const getUniqueValues = <T extends string | number>(
//     arr: T[] | null | undefined
//   ): T[] => {
//     if (!arr) return [];
//     const uniqueValues = Array.from(new Set(arr));
//     return uniqueValues;
//   };

//   const sortByCountry = (
//     a: { countryCode: string },
//     b: { countryCode: string }
//   ) => {
//     if (a.countryCode < b.countryCode) return -1;
//     if (a.countryCode > b.countryCode) return 1;
//     return 0;
//   };

//   return (
//     <>
//       {/* ASNs Card */}
//       {data.asns && data.asns.length > 0 && (
//         <Card
//           heading={"Autonomous System Number (ASNs)"}
//           actionButtons={actionButtons}
//         >
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.asns).map(
//                 (asn: string, index: number) => (
//                   <Row key={index} lbl="" val="">
//                     <span>{asn}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* Interesting URLs Card */}
//       {data.interesting_urls && data.interesting_urls.length > 0 && (
//         <Card heading={"Interesting URLs"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.interesting_urls).map(
//                 (url: string, index: number) => (
//                   <Row key={url} lbl="" val="">
//                     <span>{url}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* IPs Card with Country Flags */}
//       {/* {data.ips && data.ips.length > 0 && (
//         <Card heading={"Unique IPs"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {ipDetails
//                 .map((ipDetail, index) => ({
//                   ip: data.ips[index],
//                   countryCode: ipDetail.countryCode || "",
//                 }))
//                 .sort(sortByCountry)
//                 .map(({ ip, countryCode }, index) => (
//                   <Row key={index} lbl="" val={""}>
//                     <span>
//                       <CountryFlag
//                         countryCode={countryCode}
//                         svg
//                         style={{ marginRight: "5px" }}
//                       />
//                       {ip}
//                     </span>
//                   </Row>
//                 ))}
//             </div>
//           </div>
//         </Card>
//       )} */}

//       {data.ips && data.ips.length > 0 && (
//         <Card heading={"Unique IPs"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {ipDetails
//                 .map((ipDetail, index) => ({
//                   ip: data.ips[index],
//                   countryCode: ipDetail.countryCode || "",
//                   countryName: ipDetail.country || "",
//                 }))
//                 .sort(sortByCountry)
//                 .map(({ ip, countryCode, countryName }, index) => (
//                   <Row key={index} lbl="" val={""}>
//                     <span style={{ display: "flex", alignItems: "center" }}>
//                       <CountryFlag
//                         countryCode={countryCode}
//                         svg
//                         style={{ marginRight: "10px" }}
//                       />
//                       <span></span>
//                       <span style={{ width: "100px", textAlign: "left" }}>
//                         {countryName.length > 12 ? countryCode : countryName}
//                       </span>
//                       <span style={{ flex: "1", textAlign: "right" }}>
//                         {ip}
//                       </span>
//                     </span>
//                   </Row>
//                 ))}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* Emails Card */}
//       {data.emails && data.emails.length > 0 && (
//         <Card heading={"Emails"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.emails).map(
//                 (email: string, index: number) => (
//                   <Row key={email} lbl="" val="">
//                     <span>{email}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* Searched Hosts Card */}
//       {data.hosts && data.hosts.length > 0 && (
//         <Card heading={"Searched Hosts"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {data.hosts
//                 .map((hostEntry: { host: any; ips: any }) => {
//                   const { host, ips } = hostEntry;
//                   return { host, ips };
//                 })
//                 .sort(
//                   (
//                     a: { ips: string[]; host: string },
//                     b: { ips: string[]; host: string }
//                   ) => {
//                     if (a.ips.length > 0 && b.ips.length === 0) {
//                       return -1;
//                     } else if (a.ips.length === 0 && b.ips.length > 0) {
//                       return 1;
//                     } else {
//                       return a.host.localeCompare(b.host);
//                     }
//                   }
//                 )
//                 .map(
//                   ({ host, ips }: any, index: string | number | undefined) => (
//                     <Row key={index} lbl={host} val={ips.join(", ")} />
//                   )
//                 )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* Twitter People Card */}
//       {data.twitter_people && data.twitter_people.length > 0 && (
//         <Card heading={"Twitter People"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.twitter_people).map(
//                 (twitter_people: string, index: number) => (
//                   <Row key={twitter_people} lbl="" val="">
//                     <span>{twitter_people}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* LinkedIn People Card */}
//       {data.linkedin_people && data.linkedin_people.length > 0 && (
//         <Card heading={"LinkedIn People"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.linkedin_people).map(
//                 (linkedin_people: string, index: number) => (
//                   <Row key={linkedin_people} lbl="" val="">
//                     <span>{linkedin_people}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* LinkedIn Links Card */}
//       {data.linkedin_links && data.linkedin_links.length > 0 && (
//         <Card heading={"LinkedIn Links"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.linkedin_links).map(
//                 (linkedin_link: string, index: number) => (
//                   <Row key={linkedin_link} lbl="" val="">
//                     <span>{linkedin_link}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}

//       {/* Trello URLs Card */}
//       {data.trello_urls && data.trello_urls.length > 0 && (
//         <Card heading={"Trello URLs"} actionButtons={actionButtons}>
//           <div className="content">
//             <div>
//               {getUniqueValues<string>(data.trello_urls).map(
//                 (trello_url: string, index: number) => (
//                   <Row key={trello_url} lbl="" val="">
//                     <span>{trello_url}</span>
//                   </Row>
//                 )
//               )}
//             </div>
//           </div>
//         </Card>
//       )}
//     </>
//   );
// };

// export default ApiResultCard;
