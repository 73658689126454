import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "../../styles/OnboardingTwo.css";
import styled from "styled-components";
import * as Flags from "country-flag-icons/react/3x2";
import NetworkGraph from "../NetworkGraphComp/NetworkGraph";

type CountryCode = keyof typeof Flags;

// Function to dynamically get the country flag component based on country code
const getCountryFlagComponent = (countryCode: string | number) => {
  if (
    typeof countryCode === "string" &&
    (countryCode as CountryCode) in Flags
  ) {
    const FlagIcon = Flags[countryCode as CountryCode];
    return <FlagIcon style={{ width: "30px", height: "10px" }} />;
  } else {
    return <span>🏳️</span>; // Default to neutral flag if country code is not found
  }
};

interface OnboardingTwoProps {
  scanId: string;
  domainName: string;
  addressType: string;
  crawlResult: any;
  loading: boolean;
  isBlurred: boolean;
  onComplete: () => void; // Add this line
}

const WarningModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it appears above other content */
`;

const ModalContent = styled.div`
  background: white;
  font-family: "Orbitron", sans-serif;
  padding-top: 30px;
  padding-bottom: 30px;
  padding: 30px;
  box-shadow: 0px 1px 10px 5px #075283;
  border-radius: 8px;
  width: 30vw;
  text-align: center;

  @media (max-width: 480px) {
    width: 90vw; /* Even more responsive for very small screens */
    padding: 15px;
  }

  @media (orientation: landscape) and (max-width: 768px) {
    width: 40vw; /* Adjust width for landscape */
  }
`;

const ModalHeading = styled.h2`
  margin: 0 0 15px;
  font-size: 2rem;
  letter-spacing: 3px;

  @media (max-width: 768px) {
    font-size: 1.4rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Further adjust for smaller screens */
  }
`;

const ModalMessage = styled.p`
  margin: 0 0 20px; /* Margin below the message */
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Further adjust for smaller screens */
  }
`;

const OnbordingTwo: React.FC<OnboardingTwoProps> = ({
  domainName,
  addressType,
  crawlResult,
  loading,
  isBlurred,
  onComplete,
}) => {
  const [ipInfoData, setIpInfoData] = useState<any[]>([]);
  const [targetURL, setTargetURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false); // State for animation
  const [visibleRows, setVisibleRows] = useState<boolean[]>([]);
  const tableRef = useRef<HTMLTableSectionElement | null>(null); // Create a ref for the table
  // State to manage visibility of rows
  const [isPaused, setIsPaused] = useState(false);
  const [number, setNumber] = useState(0);
  const [showTriangle, setShowTriangle] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [circleSize, setCircleSize] = useState({
    width: "80px",
    height: "80px",
  });

  // Track if onboarding is paused
  const isPausedRef = useRef(isPaused);
  const refValue = useMemo(() => isPausedRef.current, [isPausedRef.current]);
  const handlePageClick = () => {
    setIsPaused((prev) => {
      const newPauseState = !prev; // Toggle the pause state
      // console.log('Clicked on the screen. Pause is now:', newPauseState); // Log the click and new state
      return newPauseState; // Return the new pause state
    });
    setNumber(number + 2);
    // console.log(number);
  };

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth <= 480) {
        setCircleSize({ width: "20px", height: "20px" });
      } else {
        setCircleSize({ width: "80px", height: "80px" });
      }
    };

    updateSize(); // Set initial size
    window.addEventListener("resize", updateSize); // Listen for resize events

    return () => window.removeEventListener("resize", updateSize); // Cleanup on unmount
  }, []);
  // Listen for clicks on the document body to pause/unpause
  useEffect(() => {
    document.addEventListener("click", handlePageClick);
    return () => {
      document.removeEventListener("click", handlePageClick);
      // console.log("Component unmounted: final isPaused state is", isPaused);
    };
  }, [isPaused]);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    if (!isPaused && number % 2 === 0 && number !== 0) {
      setShowTriangle(true); // Show the triangle

      // Hide the triangle after 2 seconds
      const timer = setTimeout(() => {
        setShowTriangle(false);
      }, 1000);

      // Cleanup the timer when the component unmounts or `number` changes
      return () => clearTimeout(timer);
    }
  }, [isPaused, number]);

  const maxTextLength = 24;
  // Function to truncate text
  function truncateText(text: string, maxLength: number) {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }

  useEffect(() => {
    const fetchIpInfoData = async () => {
      try {
        // Assuming crawlResult is correctly passed down
        const data = crawlResult;
        // console.log(data);

        // Ensure that crawlResult contains the expected structure
        if (!data || !data.allUrls || data.allUrls.length === 0) {
          // console.error("Invalid crawl result structure:", data);
          setIsLoading(false);
          setShowWarning(true);
          return;
        }

        setTargetURL(
          truncateText(data.targetURL, maxTextLength) || "No URL Found"
        );

        const mappedIpInfoData = data.allUrls.map((page: any) => ({
          ip: page.ipInfo?.query || "IP Unavailable",
          countryc: page.geoInfo?.countryCode || "XX",
          countryN: page.geoInfo?.country || "Unknown",
          domainName: page.url || "URL Unavailable",
        }));

        setIpInfoData(mappedIpInfoData);
      } catch (error) {
        console.error("Error processing IP info data:", error);
      } finally {
        setIsLoading(false);
        setIsAnimated(true);
      }
    };

    if (crawlResult) {
      fetchIpInfoData();
    } else {
      setIsLoading(false); // No crawl result to fetch data from
    }
  }, [crawlResult]);

  useEffect(() => {
    // Call onComplete after 5 seconds
    const timer = setTimeout(() => {
      if (!isPausedRef.current) {
        onComplete();
      }
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onComplete, isPausedRef, refValue]); // Dependency on onComplete

  useEffect(() => {
    if (ipInfoData && ipInfoData.length > 0) {
      const items = ipInfoData;
      let currentIndex = 0;

      const interval = setInterval(() => {
        if (currentIndex < items.length) {
          setData((prevData) => [...prevData, items[currentIndex]]);
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 30); // 100ms delay

      return () => clearInterval(interval);
    }
  }, [ipInfoData]);
  // console.log(data);
  // Scroll to bottom whenever data updates
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight; // Scroll to bottom
    }
  }, [data]);

  return (
    <>
      <div
        className={`container-fluid ${
          isBlurred || showWarning ? "blurred" : ""
        }`}
      >
        <Navbar />
        {loading || isLoading ? (
          <div className="loading-spinner">
            {/* <p>Loading, please wait...</p> */}
            <p>Exploring the digital jungle... Almost done!</p>
            <div className="spinner" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">URL Name: {targetURL}</div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">
                  Total Connections: {ipInfoData.length}
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">Total Tracker URL: 0</div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 mb-3">
                <div className="grid-item">
                  <div className="diagram">
                    <NetworkGraph
                      domainName={domainName}
                      crawlResult={crawlResult}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <div className="container1" ref={tableRef}>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      //  console.log(item);
                      if (item && item.countryc) {
                        return (
                          <div key={index} className="item">
                            {getCountryFlagComponent(item.countryc)}-
                            {item.countryN}:{item.domainName}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    <p className="centerp">No data available</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isPaused ? (
        <div className="overlay">
          <div className="pauseCircle">
            <div className="pauseIcon">||</div>
          </div>
        </div>
      ) : showTriangle ? (
        <div className="overlay">
          <div className="pauseCircle">
            <div className="triangle"></div>
          </div>
        </div>
      ) : null}
      {showWarning && (
        <WarningModalContainer>
          <ModalContent>
            <ModalHeading>Shh !!</ModalHeading>
            <ModalMessage># Its Looking Clean</ModalMessage>
          </ModalContent>
        </WarningModalContainer>
      )}
    </>
  );
};

export default OnbordingTwo;

// const useResponsivePauseCircle = () => {
//   const [circleSize, setCircleSize] = useState({ width: '80px', height: '80px' });

//   useEffect(() => {
//     const updateSize = () => {
//       if (window.innerWidth <= 480) {
//         setCircleSize({ width: '20px', height: '20px' });
//       } else {
//         setCircleSize({ width: '80px', height: '80px' });
//       }
//     };

//     updateSize(); // Set initial size
//     window.addEventListener('resize', updateSize); // Listen for resize events

//     return () => window.removeEventListener('resize', updateSize); // Cleanup on unmount
//   }, []);

//   return circleSize;
// };

// // eslint-disable-next-line react-hooks/rules-of-hooks
// const pauseCircleSize = useResponsivePauseCircle();

// const styles: {overlay: CSSProperties; pauseCircle: CSSProperties; pauseIcon: CSSProperties;triangle:CSSProperties} = {
//   overlay: {
//     position: 'absolute',
//     top: '3vh',
//     right: '8vw',
//     backgroundColor: 'rgba(0, 0, 0, 0)', // Light semi-transparent background
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   pauseCircle: {
//     // width: '80px',
//     // height: '80px',
//     borderRadius: '50%',
//     backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light color for the pause circle
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',

//   },
//   // pauseIcon: {
//   //   width: '20px',
//   //   height: '40px',
//   //   backgroundColor: 'rgba(0, 0, 0, 0.2)',
//   //   display: 'flex',
//   //   justifyContent: 'space-between',
//   //   alignItems: 'center',
//   // },
//   pauseIcon: {
//     color: '#034772',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     borderRadius: '50%',
//     fontSize:'30px',
//     fontWeight:'bold',
//     padding:'8px 8px',
//   },
//   triangle: {
//     width: '0px',
//     height: '0px',
//     transform: 'rotate(90deg)',
//     borderLeft: '15px solid transparent',
//     borderRight: '15px solid transparent',
//     borderBottom: '20px solid #034772',
//   }
// };

// import React, { useEffect, useRef, useState } from 'react';
// import './App.css'; // Assuming your CSS is in App.css

// const App: React.FC = () => {
//   const [data, setData] = useState<string[]>([]); // Explicitly define the type as string[]
//   const containerRef = useRef<HTMLDivElement | null>(null);

//   // Simulated data fetching
//   const fetchData = () => {
//     const items = Array.from({ length: 50 }, (_, index) => `Item ${index + 1}`);
//     return items;
//   };

//   useEffect(() => {
//     const items = fetchData();
//     let currentIndex = 0;

//     const interval = setInterval(() => {
//       if (currentIndex < items.length) {
//         setData(prevData => [...prevData, items[currentIndex]]);
//         currentIndex++;
//       } else {
//         clearInterval(interval);
//       }
//     }, 100); // 100ms delay

//     return () => clearInterval(interval);
//   }, []);

//   // Scroll to bottom whenever data updates
//   useEffect(() => {
//     if (containerRef.current) {
//       containerRef.current.scrollTop = containerRef.current.scrollHeight; // Scroll to bottom
//     }
//   }, [data]);

//   return (
//     <div className="container" ref={containerRef}>
//       {data.map((item, index) => (
//         <div key={index} className="item">
//           {item}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default App;
