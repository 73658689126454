
const colors = {

  primary: '#034772',
  primaryLighter: '#cff97a',
  textColor: '#1a2332',
  // textColor: '#ffffff',
  textColorSecondary: '#a4b1cd',
  textColorSecondar: '#b2b6cd',

  // background: '#141d2b',
  background: '#ffffff',
  backgroundDarker: '#111927',
  backgroundLighter: '#ffffff',
  // backgroundLighter: '#1a2332',
  bgShadowColor: '#034772',
  // bgShadowColor: '#0f1620',
  fgShadowColor: '#456602',
  primaryTransparent: '#9fef0012',
  orange:'#FF7B01',
  cblue:'#30AFFF',
  lightBlue:'#004C7D',
  // Action Colors
  info: '#04e4f4',
  success: '#20e253',
  warning: '#f6f000',
  error: '#fca016',
  danger: '#f80363',
  neutral: '#272f4d',
};

export default colors;
