import styled from "styled-components";
import colors from "styles/colors";
import { Card } from "components/Form/Card";
import Row from "components/Form/Row";

const Note = styled.small`
  display: block;
  margin-top: 0.5rem;
  a {
    color: ${colors.primary};
  }
`;

const ArchivesCard = (props: {
  data: any;
  title: string;
  actionButtons: any;
  icon: any;
}): JSX.Element => {
  let { data } = props;
  data = data.data || {};

  return (
    <Card
      heading={props.title}
      actionButtons={props.actionButtons}
      icon={props.icon}
    >
      <Row lbl="First Scan" val={data.firstScan || "N/A"} />
      <Row lbl="Last Scan" val={data.lastScan || "N/A"} />
      <Row lbl="Total Scans" val={data.totalScans || 0} />
      <Row lbl="Change Count" val={data.changeCount || 0} />
      <Row lbl="Avg Size" val={`${data.averagePageSize || 0} bytes`} />

      {/* Conditional rendering for scan frequency details, using optional chaining and fallback values */}
      {data.scanFrequency?.scansPerDay &&
      parseFloat(data.scanFrequency.scansPerDay) > 1 ? (
        <Row
          lbl="Avg Scans Per Day"
          val={data.scanFrequency.scansPerDay || "N/A"}
        />
      ) : (
        <Row
          lbl="Avg Days Between Scans"
          val={data.scanFrequency?.daysBetweenScans || "N/A"}
        />
      )}

      {/* Additional frequency information with fallbacks */}
      {data.scanFrequency?.changesPerDay &&
      parseFloat(data.scanFrequency.changesPerDay) > 1 ? (
        <Row
          lbl="Avg Changes Per Day"
          val={data.scanFrequency.changesPerDay || "N/A"}
        />
      ) : (
        <Row
          lbl="Avg Days Between Changes"
          val={data.scanFrequency?.daysBetweenChanges || "N/A"}
        />
      )}

      <Note>
        View historical versions of this page{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href={`https://web.archive.org/web/*/${data.scanUrl}`}
        >
          here
        </a>
        , via the Internet Archive's Wayback Machine.
      </Note>
    </Card>
  );
};

export default ArchivesCard;

// import styled from 'styled-components';
// import colors from 'styles/colors';
// import { Card } from 'components/Form/Card';
// import Row from 'components/Form/Row';

// const Note = styled.small`
// display: block;
// margin-top: 0.5rem;
// a {
//   color: ${colors.primary};
// }
// `;

// const ArchivesCard = (props: { data: any, title: string, actionButtons: any ,icon: any}): JSX.Element => {
//   const data = props.data;
//   return (
//     <Card heading={props.title} actionButtons={props.actionButtons} icon={props.icon}>
//       <Row lbl="First Scan" val={data.firstScan} />
//       <Row lbl="Last Scan" val={data.lastScan} />
//       <Row lbl="Total Scans" val={data.totalScans} />
//       <Row lbl="Change Count" val={data.changeCount} />
//       <Row lbl="Avg Size" val={`${data.averagePageSize} bytes`} />
//       { data.scanFrequency?.scansPerDay > 1 ?
//         <Row lbl="Avg Scans Per Day" val={data.scanFrequency.scansPerDay} /> :
//         <Row lbl="Avg Days between Scans" val={data.scanFrequency.daysBetweenScans} />
//       }

//       <Note>
//         View historical versions of this page <a rel="noreferrer" target="_blank" href={`https://web.archive.org/web/*/${data.scanUrl}`}>here</a>,
//         via the Internet Archive's Wayback Machine.
//       </Note>
//     </Card>
//   );
// }

// export default ArchivesCard;
