import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { determineAddressType } from "utils/address-type-checker";
// import http from "http";
import "./Home.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Nav from "components/Form/Nav";

const Home = (): JSX.Element => {
  const [userInput, setUserInput] = useState("");
  const [errorMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation(); // To capture navigation state
  const [loading, setLoading] = useState(false);
  const [randomQuote, setRandomQuote] = useState(""); // State for random quote
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  // const [showDropdown, setShowDropdown] = useState(false);
  const [successMsg, setSMsg] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);


  // const [isReachable, setIsReachable] = useState(null);
  // const [domain, setDomain] = useState("");
  // const [isReachable, setIsReachable] = useState(Boolean);
  // const [errMsg, setErrMsg] = useState("");
  const api = process.env.REACT_APP_API_ENDPOINT || "/api";

  const quotes = [
    "Every Domain Tells a Tale-Let's Uncover It.",
    "Every Domain Holds a Narrative-Join Us in Revealing It.",
    "Behind Every Domain Lies a Story-Let's Discover Together.",
    "Every Domain Has a Unique Journey-Let’s Explore.",
    "Unveiling the Stories Behind Every Domain.",
    "Every Domain Is a Story Waiting to Be Told-Let’s Share.",
    "Unlock the Narratives Hidden Within Every Domain.",
    "Every Domain Carries a Legacy-Let’s Bring It to Light.",
    "Discover the Untold Stories of Every Domain.",
    "Every Domain Has Its Own Tale-Let’s Unravel It.",
    "Let’s Bring to Light the Stories Behind Every Domain.",
    "Every Domain Is Rich with Stories-Let’s Reveal Them.",
    "Unearth the Hidden Tales of Every Domain.",
    "Every Domain Has a Backstory-Let’s Explore Together.",
    "Every Domain Is a Storybook-Let’s Turn the Pages.",
    "Every Domain Has a History-Let’s Unveil It.",
    "Every Domain Is a Journey-Let’s Share Its Story.",
    "Revealing the Stories Embedded in Every Domain.",
    "Every Domain Has Its Own Chapter-Let’s Read It Together.",
    "Every Domain Holds a Story-Let’s Bring It to Life.",
  ];
  useEffect(() => {
    // Load recent searches from localStorage on component mount
    const storedSearches = localStorage.getItem("recentSearches");
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
  }, []);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);
  }, []);

//   const CustomToast = (
    
//     props:{ message: any }) => (
//   <div style={{ display: 'flex', alignItems: 'center' }}>
//     {/* Icon with margin for spacing */}
//     <span 
//       style={{
//         color: 'white',
//         backgroundColor: 'red',
//         padding: '2px 5px',
//         borderRadius: '50%',
//         marginRight: '10px',  // This creates space between the icon and the text
//       }}
//     >
//       ⓘ
//     </span>
//     <span>{props.message}</span>
//   </div>
// );

// // Function to show the toast
// const showErrorToast = (errorMsg: unknown) => {
//   toast.error(<CustomToast message={errorMsg} />, {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     progressStyle: {
//       backgroundColor: 'red', // Set progress bar color to red
//     },
//   });
// };



  useEffect(() => {
    if (errorMsg && !isToastVisible) {
      setIsToastVisible(true);
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: ({ theme, type }) => <span style={{ color: '#cc3333' ,backgroundColor:'white',padding:'1px 5px',borderRadius:'50%',marginRight: '20px', fontSize:'20px' }}>ⓘ</span>, // Set icon color to red
      progressStyle: {
        backgroundColor: '#cc3333', // Set progress bar color to red
      },
      className: "toast-error"
      },
    
    );

      // Clear the error message after the toast is shown
      const timer = setTimeout(() => {
        setErrMsg("");
        setIsToastVisible(false); // Reset errorMsg after toast is shown
      }, 2000); // Adjust timeout as needed

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [errorMsg]);

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Clear the error message after the toast is shown
      const timer = setTimeout(() => {
        setSMsg(""); // Reset errorMsg after toast is shown
      }, 1000); // Adjust timeout as needed

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [errorMsg, successMsg]);

  useEffect(() => {
    const sessionToken = getSessionTokenFromCookie();
    if (sessionToken) {
      submit();
    } else {
      fetchSessionToken();
    }

    // If navigating back from Final.tsx, clear domainName if passed in state
    if (location.state && location.state.clearInput) {
      setUserInput("");
      localStorage.removeItem("scanData"); // Reset input on navigating back
    }
  }, [location]);

  const getSessionTokenFromCookie = () => {
    const { cookie } = document;
    const sessionTokenCookie = cookie
      .split(";")
      .find((c) => c.trim().startsWith("session_token="));
    if (sessionTokenCookie) {
      return sessionTokenCookie.split("=")[1];
    }
    return null;
  };

  const fetchSessionToken = async () => {
    try {
      const sessionToken = getSessionTokenFromCookie();
      if (!sessionToken) {
        throw Error("No existing session token found.");
      }
    } catch (error) {
      // console.log("Error fetching session token:", error);
    }
  };

  const submit = async () => {
    let address = userInput.endsWith("/") ? userInput.slice(0, -1) : userInput;
    const addressType = determineAddressType(address);

    if (addressType === "empt" || addressType === "err") {
      setErrMsg("Please enter a valid URL to scan");
    } else {
      if (addressType === "url" && !/^https?:\/\//i.test(address)) {
        address = "https://" + address;
      }

      setLoading(true);

      const whoisResponse = await fetch(`${api}/whois?url=${address}`, {
        method: "GET",
      });

      const whoisData = await whoisResponse.json();
      if (!whoisData.whoisData) {
        setErrMsg("Please enter a valid domain to scan");
        setLoading(false);
        return;
      }
      if (!whoisData.urlIsReachable) {
        setErrMsg("Domain is unreachable, Please enter a valid domain to scan");
        setLoading(false);
        return;
      }
      let description = null; // Initialize the description variable

      if (whoisData.metaTags) {
        const metaTags = whoisData.metaTags;

        // Check if 'description' or 'og:description' exists and set it to description
        description =
          metaTags.description || metaTags["og:description"] || null;
      }

      addRecentSearch(address);
      await startScan(address, addressType, description);
      setSMsg("Let's reveal domain's background!")

      setUserInput(""); // Clear input after submission
    }
  };
  const addRecentSearch = (search: string) => {
    const updatedSearches = [...recentSearches, search].slice(-5); // Store the last 5 searches
    setRecentSearches(updatedSearches);
    localStorage.setItem("recentSearches", JSON.stringify(updatedSearches)); // Save in localStorage
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      submit();
      // setLoading(false);
    }
  };

  const startSequentialScans = async (address: string, addressType: string) => {
    try {
      const api = process.env.REACT_APP_API_ENDPOINT || "/api";

      const apiResponse = await fetch(`${api}/startScan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address }),
      });

      const apiData = await apiResponse.json();

      const mtrData = {
        message: "MTR Traceroute completed!",
        result: [
          {
            hopNumber: "1",
            host: "192.168.1.1",
            packetLoss: "0.0",
            sentPackets: "10",
            lastTime: "1.9",
            avgTime: "5.0",
            bestTime: "1.4",
            worstTime: "30.2",
            stdDev: "9.0",
            ipInfo: {
              status: "fail",
              message: "private range",
              query: "192.168.1.1",
            },
          },
        ],
      };

      if (mtrData.message === "MTR Traceroute completed!" && mtrData.result) {
        return {
          apiScanId: apiData.scanId,
          mtrResult: mtrData.result,
        };
      } else {
        throw new Error("Failed to retrieve MTR scan result.");
      }
    } catch (error) {
      console.error("Error during sequential scans:", error);
      throw new Error("Failed to communicate with the server for scans.");
    }
  };

  const startScan = async (
    address: string,
    addressType: string,
    description: string
  ) => {
    try {
      setLoading(true);
      const scanData = await startSequentialScans(address, addressType);
      setSMsg("Let's reveal domain's background!");
      if (scanData.apiScanId && scanData.mtrResult) {
        localStorage.setItem("scanData", JSON.stringify(scanData));
        toast.success(
          "lets reveal domains together"
        )
        navigate(`/results/${scanData.apiScanId}`, {
          state: {
            clearInput: true, // Flag to clear input on back navigation
            scanId: scanData.apiScanId,
            domainName: address,
            addressType: addressType,
            description: description,
          },
        });
      } else {
        setErrMsg("Failed to start scan. Please try again.");
      }
    } catch (error) {
      console.error("Error starting the scan:", error);
      setErrMsg("Failed to communicate with the server. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
    const isError = ["err", "empt"].includes(
      determineAddressType(event.target.value)
    );
    if (!isError) {
      setErrMsg("");
    }
  };
  // const handleRecentSearchClick = (search: string) => {
  //   setUserInput(search);
  //   submit(); // Trigger the submit when a recent search is clicked
  // };
  // const handleRecentSearchClick = (search: string) => {
  //   setUserInput(search);
  //   submit(); // Trigger the submit when a recent search is clicked
  // };
  return (
    <div className={`scanner-container ${loading ? "blur" : ""}`}>
      {/* <div className="scanner-container"> */}
      <div className="logo-container">
        {/* <img
        width="256px"
        className="mainRmlogo"
        src="/rm-logo.png"
        alt="Redmorph Icon"
      /> */}
        <Nav></Nav>
      </div>
      <div style={{ marginTop: "1rem" }}></div>
      {/* <h2 className="title">
        Ultimate scanner for consolidated website profile and threat vectors
        data
      </h2> */}
      <h2 className="home-title">{randomQuote}</h2>
      <div className="search-box">
        <button className="search-icon" onClick={submit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5rem"
            height="1.5rem"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 1 0-14 0 7 7 0 0 0 14 0z"
            />
          </svg>
        </button>
        <input
          type="text"
          placeholder="Please enter web domain to scan"
          value={userInput}
          onChange={inputChange}
          onKeyDown={handleKeyDown}
        />
        <div style={{marginRight:'0.5rem'}}>
        <button className={userInput ? "icon3" : "icon2"} onClick={submit}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
        </div>
      </div>{" "}
       {/* Recent Searches Button */}
      {/* <button
          className="recent-searches-icon"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          🔍 Recent
        </button>

       
        {showDropdown && (
          <ul className="recent-searches-dropdown">
            {recentSearches.length > 0 ? (
              recentSearches.map((search, index) => (
                <li key={index} onClick={() => handleRecentSearchClick(search)}>
                  {search}
                </li>
              ))
            ) : (
              <li>No recent searches</li>
            )}
          </ul>
        )} */}
      {/* <button className= {`search-button ${loading ? 'search' : ''}`} onClick={submit} disabled={loading}>
       { loading? "Loading..." : "Search"}
      </button> */}
      <div className="divp">
        <p>
          By using RedMorph, you agree to our{" "}
          <a href="https://redmorph.com/termsAndConditions.html" target="_blank" className="link" rel="noreferrer">
            Terms
          </a>{" "}
          and have read our{" "}
          <a href="https://redmorph.com/privacyPolicy.html" target="_blank" className="link" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </p>
      </div>
      {/* <div className="error">
        {errorMsg && <p className="error-message">{errorMsg}</p>}
      </div> */}
      <ToastContainer />
    </div>
  );
};

export default Home;
