import React, {
  useEffect,
  useRef,
  useState,
  CSSProperties,
  useMemo,
} from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { geoMercator, geoPath } from "d3-geo";
import { feature } from "topojson-client";
import { FeatureCollection } from "geojson";
import "../../styles/OnboardingOne.css";
import * as Flags from "country-flag-icons/react/3x2";
import gpsLogo from "../../assets/location-pin.svg";
import serverpack from "../../assets/server-svgrepo.svg";

import Navbar from "../Navbar/Navbar";
import "../../styles/OnboardingOne.css";

interface Location {
  latitude: number;
  longitude: number;
  image?: string;
}

interface TraceRoute {
  location: string;
  ip: string;
  country: string;
  countryCode: string;
  lat: number | null;
  lon: number | null;
}

const WarningModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  font-family: "Rajdhani", sans-serif;
  padding: 30px;
  box-shadow: 0px 1px 10px 5px #075283;
  border-radius: 8px;
  width: 30vw;
  text-align: center;

  @media (max-width: 480px) {
    width: 90vw; /* Even more responsive for very small screens */
    padding: 15px;
  }
  @media (orientation: landscape) and (max-width: 768px) {
    width: 40vw; /* Adjust width for landscape */
  }
`;

const ModalHeading = styled.h2`
  margin: 0 0 15px;
  font-size: 2rem;
  letter-spacing: 3px;

  @media (max-width: 768px) {
    font-size: 1.4rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Further adjust for smaller screens */
  }
`;

const ModalMessage = styled.p`
  margin: 0 0 20px;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Further adjust for smaller screens */
  }
`;

const OnboardingOne: React.FC<{
  scanId: string;
  domainName: string;
  addressType: string;
  mtrResult: any; // The API result
  loading: boolean; // The loading status
  isBlurred: boolean;
  onComplete: () => void; // Add this line
  description: string;
}> = ({
  scanId,
  domainName,
  addressType,
  mtrResult,
  loading,
  isBlurred,
  onComplete,
  description,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [traceRouteData, setTraceRouteData] = useState<TraceRoute[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [userCity, setUserCity] = useState();
  const [userCountry, setUserCountry] = useState<string>();
  const [serverCity, setServerCity] = useState<string>();
  const [serverCountry, setServerCountry] = useState<string>();
  const [number, setNumber] = useState(0);
  const [showTriangle, setShowTriangle] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  // Track if onboarding is paused
  const isPausedRef = useRef(isPaused);
  const refValue = useMemo(() => isPausedRef.current, [isPausedRef.current]);

  // Toggle pause/unpause state when the user clicks anywhere on the page
  const handlePageClick = () => {
    setIsPaused((prev) => {
      const newPauseState = !prev; // Toggle the pause state

      // console.log('Clicked on the screen. Pause is now:', newPauseState); // Log the click and new state
      return newPauseState; // Return the new pause state
    });
    setNumber(number + 2);
  };
  // Listen for clicks on the document body to pause/unpause
  useEffect(() => {
    document.addEventListener("click", handlePageClick);
    // console.log("Component mounted: initial isPaused state is", isPaused);
    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePageClick);
      // console.log("Component unmounted: final isPaused state is", isPaused);
    };
  }, [isPaused]);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    if (!isPaused && number % 2 === 0 && number !== 0) {
      setShowTriangle(true); // Show the triangle

      // Hide the triangle after 2 seconds
      const timer = setTimeout(() => {
        setShowTriangle(false);
      }, 1000);

      // Cleanup the timer when the component unmounts or `number` changes
      return () => clearTimeout(timer);
    }
  }, [isPaused, number]);

  const messages = [
    "Finding your location... Just a moment!",
    "Finding server location... Hang tight!",
    // "Preparing to send packets... Almost there!",
    "Sending packets on a global tour... Just a moment!",
  ];

  useEffect(() => {
    if (!mtrResult || loading) {
      // console.log("Data is still loading or mtrResult is not available");
      // setShowWarning(true);
      return;
    }
    // console.log("MTR result is:", mtrResult);

    if (mtrResult.result && mtrResult.result.length > 0) {
      const result = mtrResult.result;
      // console.log(result);

      const filteredResults = result.filter(
        (item: { type: string }) => item.type === "User IP"
      );
      // const userLocation = result[0];
      // setUserCity(userLocation?.geoInfo.city);
      // setUserCountry(userLocation?.geoInfo.country);

      const citiesAndCountries = filteredResults.map(
        (item: { geoInfo: { city: any; country: any } }) => {
          return {
            city: item.geoInfo.city,
            country: item.geoInfo.country,
          };
        }
      );
      const maxTextLength = 6;
      // Function to truncate text
      function truncateText(text: string, maxLength: number) {
        return text.length > maxLength
          ? text.slice(0, maxLength) + "..."
          : text;
      }
      // const truncatedCountry = truncateText(citiesAndCountries[0].country, maxTextLength);

      // console.log("Hello USer", citiesAndCountries[0].city);
      setUserCity(citiesAndCountries[0].city);
      setUserCountry(
        truncateText(citiesAndCountries[0].country, maxTextLength)
      );

      if (
        result[result.length - 1].geoInfo &&
        !result[result.length - 1].geoInfo.error &&
        result[result.length - 1].geoInfo.latitude !== "N/A" &&
        result[result.length - 1].geoInfo.longitude !== "N/A"
      ) {
        const lastItem = result[result.length - 1];
        const lastCity = lastItem.geoInfo.city;
        const lastCountry = lastItem.geoInfo.country;
        const truncatedCountry = truncateText(lastCountry, maxTextLength);

        setServerCity(lastCity);
        setServerCountry(truncatedCountry);
      } else {
        const lastItem = result[result.length - 2];
        const lastCity = lastItem.geoInfo.city;
        const lastCountry = lastItem.geoInfo.country;

        setServerCity(lastCity);
        setServerCountry(lastCountry);
      }

      const mappedLocations = mtrResult.result
        .map((loc: any) => {
          const { geoInfo } = loc;
          if (
            geoInfo &&
            !geoInfo.error &&
            geoInfo.latitude !== "N/A" &&
            geoInfo.longitude !== "N/A"
          ) {
            return {
              latitude: geoInfo.latitude,
              longitude: geoInfo.longitude,
              ip: geoInfo.ip,
              country: geoInfo.country,
              countryCode: geoInfo.countryCode,
            };
          }
          return null;
        })
        .filter((loc: any) => loc !== null);

      setLocations(mappedLocations);

      const mappedData = mtrResult.result.map((loc: any) => ({
        ip: loc.ip || "N/A",
        country:
          loc.geoInfo && loc.geoInfo.country ? loc.geoInfo.country : "Unknown",
        countryCode:
          loc.geoInfo && loc.geoInfo.countryCode
            ? loc.geoInfo.countryCode
            : null,
      }));
      setTraceRouteData(mappedData);
      setShowWarning(false);
    } else {
      // console.error("Invalid mtrResult data");
      setShowWarning(true);
      // Call onComplete after 5 seconds
      const timer = setTimeout(() => {
        onComplete();
        setShowWarning(false);
      }, 5000); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
      // onComplete();
    }
  }, [loading, mtrResult, onComplete]);

  useEffect(() => {
    if (locations.length === 0) {
      return;
    }

    const width = 900;
    const height = 600;
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");
    const projection = geoMercator()
      .scale(120)
      .translate([width / 2, height / 1.4]);
    const path = geoPath().projection(projection);

    svg.selectAll("*").remove();

    d3.json("https://unpkg.com/world-atlas/countries-50m.json").then(
      (data: any) => {
        const countries = feature(
          data,
          data.objects.countries
        ) as unknown as FeatureCollection;

        svg
          .selectAll(".country")
          .data(countries.features)
          .enter()
          .append("path")
          .attr("class", "country")
          .attr("d", path as any)
          .attr("fill", "#075283")
          .attr("stroke", "#fff")
          .attr("stroke-width", 0.5);

        const locationsGroup = svg.append("g").attr("class", "locations");
        locations.forEach((location, i) => {
          const [x, y] = projection([
            location.longitude,
            location.latitude,
          ]) as [number, number];
          // Check if it's the starting point

          if (i === 0) {
            locationsGroup
              .append("image")
              .attr("xlink:href", gpsLogo) // Use startingPointIcon
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
            locationsGroup
              .append("rect")
              .attr("x", x - 50)
              .attr("y", y - 35) // Position slightly above the image
              .attr("width", 120)
              .attr("height", 20)
              .attr("fill", "white")
              .attr("rx", 4);
            // .on("mouseover", function () {
            //   // Use d3 to select the associated text using the same data or an identifier
            //   d3.select(this.parentNode as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition() // Add a transition effect
            //     .duration(200) // Time in ms for the effect
            //     .attr("font-size", "14px"); // Enlarge the text size
            // })
            // .on("mouseout", function () {
            //   d3.select(this.parentNode as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition()
            //     .duration(200)
            //     .attr("font-size", "10px"); // Revert the text size back
            // });

            locationsGroup
              .append("text")
              .attr("x", x) // Position text in the center horizontally
              .attr("y", y - 20) // Position text above the image
              .attr("text-anchor", "middle") // Align text to center
              .attr("font-size", "10px")
              .attr("fill", "#000")
              .text(`${userCity},${userCountry}`);
          }
          // Check if it's the ending point
          else if (i === locations.length - 1) {
            locationsGroup
              .append("image")
              .attr("xlink:href", serverpack) // Use endingPointIcon
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
            locationsGroup
              .append("rect")
              .attr("x", x - 30)
              .attr("y", y - 55) // Position slightly above the image
              .attr("width", 120)
              .attr("height", 20)
              .attr("fill", "white")
              .attr("rx", 4);
            // .on("mouseover", function () {
            //   // Use d3 to select the associated text using the same data or an identifier
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition() // Add a transition effect
            //     .duration(200) // Time in ms for the effect
            //     .attr("font-size", "14px"); // Enlarge the text size
            // })
            // .on("mouseout", function () {
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition()
            //     .duration(200)
            //     .attr("font-size", "10px"); // Revert the text size back
            // });

            locationsGroup
              .append("text")
              .attr("x", x + 30) // Position text in the center horizontally
              .attr("y", y - 40) // Position text above the image
              .attr("text-anchor", "middle") // Align text to center
              .attr("font-size", "10px")
              .attr("fill", "#000")
              .text(`${serverCity},${serverCountry}`);
          }
          // For intermediate points
          else {
            locationsGroup
              .append("image")
              .attr("xlink:href", serverpack) // Use default gpsLogo
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
            // locationsGroup
            // .append("rect")
            // .attr("x", x-30)
            // .attr("y", y - 55) // Position slightly above the image
            // .attr("width", 100)
            // .attr("height", 20)
            // .attr("fill", "white")
            // .attr("rx", 4)
            // .on("mouseover", function () {
            //   // Use d3 to select the associated text using the same data or an identifier
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition() // Add a transition effect
            //     .duration(200) // Time in ms for the effect
            //     .attr("font-size", "14px"); // Enlarge the text size
            // })
            // .on("mouseout", function () {
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition()
            //     .duration(200)
            //     .attr("font-size", "10px"); // Revert the text size back
            // });

            // locationsGroup
            // .append("text")
            // .attr("x", x+20) // Position text in the center horizontally
            // .attr("y", y - 40) // Position text above the image
            // .attr("text-anchor", "middle") // Align text to center
            // .attr("font-size", "10px")
            // .attr("fill", "#000")
            // .text(`${serverCity},${serverCountry}`);
          }
        });

        const linePaths: d3.Selection<
          SVGPathElement,
          unknown,
          SVGSVGElement,
          unknown
        >[] = [];
        locations.forEach((location, i) => {
          if (i < locations.length - 1) {
            const source = location;
            const target = locations[i + 1];

            const arcGenerator = (
              source: [number, number],
              target: [number, number]
            ) => {
              const dx = target[0] - source[0];
              const dy = target[1] - source[1];
              const dr = Math.sqrt(dx * dx + dy * dy);
              return `M${source[0]},${source[1]}A${dr},${dr} 0 0,1 ${target[0]},${target[1]}`;
            };

            const sourcePos = projection([
              source.longitude,
              source.latitude,
            ]) as [number, number];
            const targetPos = projection([
              target.longitude,
              target.latitude,
            ]) as [number, number];

            const linePath = svg
              .append("path")
              .attr("class", "arc")
              .attr("d", arcGenerator(sourcePos, targetPos))
              .attr("fill", "none")
              .attr("stroke", "#FF3131")
              .attr("stroke-width", 1.8)
              .attr("stroke-dasharray", "5,5");

            linePaths.push(linePath as any);
          }
        });

        if (linePaths.length > 0) {
          const planeImage = svg
            .append("image")
            .attr("xlink:href", serverpack)
            .attr("width", 30)
            .attr("height", 30)
            .attr("opacity", 0);

          const animateAlongPath = (pathIndex: number) => {
            // if (isPausedRef.current) {
            const linePath = linePaths[pathIndex];
            const totalLength = linePath.node()?.getTotalLength() ?? 0;

            planeImage
              .attr("opacity", 1)
              .attr("transform", `translate(-15, -15)`);

            planeImage
              .transition()
              .duration(2500)
              .attrTween("transform", function () {
                return function (t: number) {
                  const point = linePath
                    .node()
                    ?.getPointAtLength(t * totalLength);
                  return point
                    ? `translate(${point.x - 15}, ${point.y - 15})`
                    : "";
                };
              })
              .ease(d3.easeLinear)
              .on("end", () => {
                if (pathIndex < linePaths.length - 1) {
                  animateAlongPath(pathIndex + 1);
                } else {
                  planeImage
                    .transition()
                    .duration(500)
                    .attr("opacity", 0)
                    .on("end", () => {
                      if (!isPausedRef.current) {
                        onComplete(); // Call the onComplete function to go to OnboardingTwo
                      }
                    });
                }
              });
          };
          animateAlongPath(0);
        }
      }
    );
  }, [locations, onComplete, refValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % messages.length;

        // If the next index is 0, it means we have cycled through all messages
        if (nextIndex === 0) {
          clearInterval(interval); // Stop the interval
        }

        return nextIndex;
      });
    }, 1500); // Change the message every 1.5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [messages.length]);

  const getCountryFlagComponent = (countryCode: string) => {
    const FlagComponent = (Flags as any)[countryCode];
    return FlagComponent ? (
      <FlagComponent
        title={countryCode}
        style={{ width: "15px", marginRight: "5px" }}
      />
    ) : (
      <span>🏳️</span>
    );
  };
  return (
    <>
      <div
        className={`container-fluid ${
          isBlurred || showWarning ? "new-blurred" : ""
        }`}
      >
        <Navbar />
        {loading ? (
          // <div className="loading-spinner">
          //   {/* <p>Loading, please wait...</p> */}
          //   <p>Sending packets on a global tour... Just a moment!</p>
          //   <div className="spinner" />
          // </div>

          <div className="loading-spinner">
            <p>{messages[messageIndex]}</p>
            <div className="spinner" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="header">
                  <div className="header-title">Trace Route</div>
                  <div className="header-description">
                    <p style={{ lineHeight: "1.3", letterSpacing: "0.5px" }}>
                      Trace route maps can be useful for network administrators
                      to pinpoint where delays or disruptions are occurring
                      along a network path.
                    </p>
                    {/* <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent click from bubbling up
                        setIsPaused((prevPaused) => !prevPaused);
                        console.log(isPaused);
                      }}
                      className="absolute top-10 right-10 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      {isPaused ? "Play" : "Pause"}
                    </button> */}
                  </div>
                </div>
              </div>
              {/* New header on the right side */}
              <div className="col-12 col-md-4 offset-md-4">
                <div className="second-header">
                  <div className="header-title">Route Information</div>
                  <div className="header-description">
                    {traceRouteData.length > 0 ? (
                      <p>
                        Packets are transferred from{" "}
                        <strong style={{ color: "#075283" }}>
                          {traceRouteData[0]?.country || "Unknown"}
                        </strong>{" "}
                        to{" "}
                        <strong style={{ color: "#075283" }}>
                          {traceRouteData[traceRouteData.length - 1]?.country ||
                            "Unknown"}
                        </strong>
                      </p>
                    ) : (
                      <p>No route data available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <div className="map-container">
                  <svg
                    ref={svgRef}
                    className="svg"
                    preserveAspectRatio="xMidYMid meet"
                  />
                </div>
              </div>
            </div>
            {/* <div className="bottomStyle"> */}

            {/* Legends */}
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="legend-center-bottom">
                  <div className="legend-title">Legends</div>
                  <div className="legend-item">
                    <img
                      className="legend-icon"
                      src={gpsLogo}
                      alt="Your Location"
                    />
                    <div>Your Location</div>
                    <div className="bottomlogo-vline"></div>
                    <img
                      className="legend-icon"
                      src={serverpack}
                      alt="Server Location"
                    />
                    <div>Server Location</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-12 col-md-3">
                <div className="traceroutestyle">
                  <div className="header-title2">Trace Route Details</div>
                  <table style={{ width: "100%", marginTop: "10px" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>IP</th>
                        <th style={{ textAlign: "left" }}></th>
                        <th style={{ textAlign: "left" }}>Country</th>
                      </tr>
                    </thead>
                    <tbody>
                      {traceRouteData.length > 0 ? (
                        traceRouteData.map((loc: any, index: number) => (
                          <tr key={index} style={{ height: "15px" }}>
                            <td>{loc.ip}</td>
                            <td>{getCountryFlagComponent(loc.countryCode)}</td>
                            <td>{loc.country || "Unknown"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Legend Container */}
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="legend-container">
                  <p>
                    Visualize the trace route map of the servers that the
                    websites you are browsing are connecting with.
                  </p>
                </div>
              </div>
            </div>
            {/* </div> */}
          </>
        )}
        {isPaused ? (
          <div className="overlay1">
            <div className="pauseCircle1">
              <div className="pauseIcon1">||</div>
            </div>
          </div>
        ) : showTriangle ? (
          <div className="overlay1">
            <div className="pauseCircle1">
              <div className="triangle1"></div>
            </div>
          </div>
        ) : null}
        {/* {!isPaused && (
        <div style={styles.overlay}>
          <div style={styles.pauseCircle}>

          <div style={styles.triangle}>
            </div>
            
          </div>
        </div>
      )} */}
      </div>
      {showWarning && (
        <WarningModalContainer>
          <ModalContent>
            <ModalHeading>Be Careful !!</ModalHeading>
            <ModalMessage># No Trace Found</ModalMessage>
          </ModalContent>
        </WarningModalContainer>
      )}
    </>
  );
};

export default OnboardingOne;
