import styled from 'styled-components';

import ErrorBoundary from 'components/misc/ErrorBoundary';
// import Heading from 'components/Form/Heading';
import colors from 'styles/colors';
import { ReactNode } from 'react';
import Heading2 from './Heading2';

export const StyledCard = styled.section<{ $styles?: string}>`
  background: ${colors.backgroundLighter};
  border: 1.5px solid;
  border-radius: 8px 8px 0 8px;
  border-color: ${colors.bgShadowColor};
  padding: 0.5rem 1rem 1rem;
  position: relative;
  margin: 0.5rem;
  max-height: 40rem;
  overflow: auto;
  font-family: 'Rajdhani';
  font-size: 14px;
  // font-family: 'Orbitron';
  ${props => props.$styles}
    @media (max-width: 480px) {
  display: flex;
  flex-direction: column;
}
  @media (max-width: 430px) {
 
    max-width:89vw;
  }
      @media (max-width: 370px) {
    display: flex;
    flex-direction: column;
    max-width:85vw;
    /* Add any other desired styles for smaller screens */
  }
@media (max-width: 768px) {
  display: flex;
  flex-direction: column;
}
`;

const StyledHr = styled.hr`
  border: none;
  border-top: 2px solid #FF8B00; // Customize thickness and color
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: calc(100% + 2rem); // Adjust to account for padding
  margin-left: -1rem; // Offset padding on the left
  margin-right: -1rem; // Offset padding on the right
`;

interface CardProps {
  children: React.ReactNode;
  heading?: string,
  styles?: string;
  actionButtons?: ReactNode | undefined;
  icon?: any;
};

export const Card = (props: CardProps): JSX.Element => {
  const { children, heading, styles, actionButtons,icon } = props;
  return (
<ErrorBoundary title={heading}>
      <StyledCard $styles={styles}>
        {/* { actionButtons && actionButtons } */}
        { heading &&
        <>
        <Heading2 className="inner-heading" as="h4" color={colors.primary} icon={icon} actionbuttons={actionButtons}>{heading}</Heading2>
        <StyledHr />
        </> }
        {children}
      </StyledCard>
    </ErrorBoundary>
  );
}

export default StyledCard;
