import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

const StyledFooter = styled.footer`
  color: white;
  bottom: 0;
  text-align: center;
  padding: 0.5rem 0;
  background: ${colors.backgroundLighter};
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  opacity: 0.75;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }

  @media (max-width: 370px) {
    min-width: 45vh;
    }
`;

// const Link = styled.a`
//   color: ${colors.primary};
//   font-weight: bold;
//   border-radius: 4px;
//   padding: 0.1rem;
//   transition: all 0.2s ease-in-out;
//   &:hover {
//     background: ${colors.primary};
//     color: ${colors.backgroundDarker};
//     text-decoration: none;
//   }
// `;

const RedmorphInc = styled.span`
  margin: 0 0.5rem;
  text-align: center;
  color: #004C7D; /* Red color */
  cursor: pointer;
`;

const CopyrightSymbol = styled.span`
  font-size: 1.5em; /* Adjust the size as needed */
  margin-right: 0.2em;
  color: #004C7D; /* Add space to the right of the symbol */
`;
const HorizontalLine = styled.hr`
  border: none;
  min-width:100vw;
  height: 2px;
  background-color: #002642; /* Adjust the color as needed */
  margin: 20px 0; /* Adjust the margin as needed */
`;

const Footer = (props: { isFixed?: boolean }): JSX.Element => {
  return (
    <StyledFooter style={props.isFixed ? { position: "fixed" } : {}}>
      <HorizontalLine/>
      <CopyrightSymbol>©</CopyrightSymbol>
      <span style={{color:'#004C7D'}}> 2019-2024 All rights reserved by </span>
      <RedmorphInc
        onClick={() => window.open("https://redmorph.com", "_blank", "noopener,noreferrer")}
      >
        Redmorph Inc.
      </RedmorphInc>
    </StyledFooter>
  );
};

export default Footer;
