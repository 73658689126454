import styled from "styled-components";
import ErrorBoundary from "components/misc/ErrorBoundary";
import Heading from "components/Form/Heading";
import colors from "styles/colors";
import { ReactNode } from "react";

export const StyledCard = styled.section<{ $styles?: string }>`
  background: ${colors.backgroundLighter};
  display: flex;
  justify-content: space-between;
  border: 1.5px solid;
  border-radius: 8px 8px 0 8px;
  border-color: ${colors.bgShadowColor};
  padding: 1rem;
  margin-left:0.7rem;
  margin-right: 0.7rem;
  position: relative;
  //   margin: 0 1rem 0 1rem;
  max-height: 40vh;
  overflow: auto !important;
  // font-family: 'Orbitron';
  font-family: "Rajdhani";
  ${(props) => props.$styles};

  @media (max-width: 480px) {
    display: flex;
    margin-left: 1rem !important;
    margin-right: 0.8rem !important;
    flex-direction: column;
    background: ${colors.backgroundLighter};
    border: 1.5px solid;
    border-radius: 8px 8px 0 8px;
    overflow: auto;
  }

  @media (max-width: 430px) {
    display: flex;
    // margin: calc(0.5rem - 1.5px);  // margin-left :1.2rem !important;
    flex-direction: column;
    background: ${colors.backgroundLighter};
    // box-shadow: 3px 3px 3px 3px ${colors.bgShadowColor};
    border: 1.5px solid;
    border-radius: 8px 8px 0 8px;
    overflow: auto;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    background: ${colors.backgroundLighter};
    // box-shadow: 3px 3px 3px 3px ${colors.bgShadowColor};
    border: 1.5px solid;
    border-radius: 8px 8px 0 8px;
    overflow: auto;
    margin-left: 1.5rem !important;
    margin-right: 1.2rem !important;
    // margin-left: 1.5rem;
    // margin-right:1rem !important;
    > * {
      flex-basis: 48%; // Two items in a row, taking 48% width to fit in two per row
      margin-bottom: 1rem; // Add some space between rows
    }
  }
  @media (max-width: 1100px) {
    margin-left: 1.3rem;
    margin-right: 0.8rem;
    // display: flex;
    // flex: wrap;
    // height: auto;
    // justify-content: center;
    // flex-direction: column;
    // flex-basis: 40vh;
    // background: ${colors.backgroundLighter};
    // // box-shadow: 3px 3px 3px 3px ${colors.bgShadowColor};
    // border: 1.5px solid;
    // border-radius: 8px 8px 0 8px;
    // overflow: auto;
    // margin-left: 1.5rem;
    // // margin-right:1rem !important;
  }
`;

interface CardProps {
  children: React.ReactNode;
  heading?: string;
  styles?: string;
  actionButtons?: ReactNode | undefined;
}

export const Card3 = (props: CardProps): JSX.Element => {
  const { children, heading, styles, actionButtons } = props;
  return (
    <ErrorBoundary title={heading}>
      <StyledCard $styles={styles}>
        {actionButtons && actionButtons}
        {heading && (
          <Heading className="inner-heading" as="h4" color={colors.primary}>
            {heading}
          </Heading>
        )}
        {children}
      </StyledCard>
    </ErrorBoundary>
  );
};

export default StyledCard;

// background: ${colors.backgroundLighter};
// border: 1.5px solid;
// border-radius: 8px 8px 0 8px;
// display: flex;
// justify-content: space-between;
// border-radius: 8px;
// border-color: ${colors.bgShadowColor};
// padding: 1rem;
// position: relative;
// margin: 0.8rem;
// max-height: 75rem;
// overflow: auto;
// font-family: 'Orbitron';
// // ${props => props.styles}
