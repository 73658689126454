import styled from "styled-components";
import { ServerLocation } from "utils/result-processor";
// import { Card2 } from "components/Form/Card2";
// import LocationMap from "components/misc/LocationMap";
import Flag from "components/misc/Flag";
// import { TextSizes } from "styles/typography";
import Row from "components/Form/Row";
import { Card1 } from "components/Form/Card1";

const cardStyles = "";

// const SmallText = styled.span`
//   // opacity: 0.5;
//   font-size: ${TextSizes.xSmall};
//   text-align: right;
//   display: block;
// `;

// const MapRow = styled(StyledRow)`
//   padding-top: 1rem;
//   flex-direction: column;
// `;

const CountryValue = styled.span`
  display: flex;
  gap: 0.5rem;
  font-size: 14px;
  align-items: any;
`;

const ServerLocationCard = (props: {
  data: ServerLocation;
  title: string;
  actionButtons: any;
  icon: any;
}): JSX.Element => {
  const location = props.data;
  const {
    ip,
    network,
    version,
    city,
    region,
    country,
    country_code,
    postal,
    timezone,
    currency,
    currency_name,
    languages,
    country_area,
    country_population,
    asn,
    org,
  } = location;

  return (
    <Card1
      heading={props.title}
      actionButtons={props.actionButtons}
      styles={cardStyles}
      icon={props.icon}
    >
      <Row lbl="IP" val={ip} />
      <Row lbl="Network" val={network} />
      <Row lbl="Version" val={version} />
      <Row lbl="City" val={`${postal}, ${city}, ${region}`} />
      <Row lbl="" val="">
        <b style={{fontSize:'12px'}}>Country</b>
        <CountryValue>
          {country}
          {country_code && <Flag countryCode={country_code} width={28} />}
        </CountryValue>
      </Row>
      <Row lbl="Timezone" val={timezone} />
      <Row lbl="Languages" val={languages} />
      <Row lbl="Currency" val={`${currency} (${currency_name})`} />
      <Row lbl="Country Area" val={country_area} />
      <Row lbl="Country Population" val={country_population} />
      <Row lbl="ASN" val={asn} />
      <Row lbl="Organization" val={org} />
      </Card1>
      

  );
};

export default ServerLocationCard;
