import React from 'react';
import styles from './Navbar.module.css'; 
import logo from '../../assets/logo.png'; 
import secondLogo from '../../assets/Xlogo.png'; 

const Navbar: React.FC = () => {
  return (
    <div className={styles.navbar}>
      {/* Primary logo */}
      <a href="/">

      <img src={logo} alt="Logo" className={styles.logo} />
      </a>
      
      {/* Vertical Line as a visual separator */}
      <div className={styles.vertical}></div>
      
      {/* Secondary logo */}
      <img src={secondLogo} alt="Second Logo" className={styles.logo} />
    </div>
  );
};

export default Navbar;
